<script lang="ts">
  import { onMount } from "svelte";
  import { sections, URLItem } from "../data/sections";
  import { ExternalURLs } from "../data/urls";
  import LoginMenu from "./LoginMenu.svelte";
  import Sidebar from "./Sidebar.svelte";
  import UserMenu from "./UserMenu.svelte";
  export let sectionKey: string;
  export let userName: string;
  export let isAuthenticated: boolean;
  export let partnerLogoURI: string;
  export let partnerTitle: string;

  let urls: URLItem[] = [];
  let sectionTitle: string;
  onMount(() => {
    const index = sections.findIndex((s) => s.sectionKey === sectionKey);
    if (index >= 0) {
      const section = sections[index];
      sectionTitle = section.sectionTitle;
      urls = section.navigationURLS;
    }
  });
</script>

<nav aria-label="primary links">
  <div class="mxs-h-10 mxs-hidden md:mxs-block mxs-bg-maxsold-dark-grey-alt">
    <div
      class="mxs-flex mxs-items-center mxs-justify-center mxs-h-10 mxs-gap-4"
    >
      <a
        class="maxsold-link-white mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.auctions}>Auctions</a
      >
      <a
        class="maxsold-link-white mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.sellEverything}>Sell Everything</a
      >
      <a
        class="maxsold-link-white mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.partners}>Partners</a
      >
      <a
        class="maxsold-link-white mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.enterprise}>Enterprise</a
      >
      <a
        class="maxsold-link-white mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.about}>About</a
      >
      <a
        class="maxsold-link-white mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.support}>Support</a
      >
    </div>
  </div>
</nav>

<div
  class="mxs-flex mxs-items-center mxs-justify-between mxs-px-4 mxs-bg-white mxs-h-[76px] lg:mxs-h-24 lg:mxs-px-20"
>
  <div class="mxs-flex mxs-items-center mxs-gap-4">
    <div class="md:mxs-hidden mxs-cursor-pointer">
      <Sidebar />
    </div>
    <div class="mxs-items-center mxs-gap-2 mxs-flex">
      <a href={ExternalURLs.homepage}>
        <svg class="mxs-h-8 mxs-w-40 sm:mxs-h-10 sm:mxs-w-56 mxs-cursor-pointer" width="190" height="36" viewBox="0 0 190 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M182.987 22.5422C182.987 20.9704 182.678 19.6246 182.062 18.5007C181.446 17.3769 180.615 16.5143 179.568 15.9129C178.521 15.3116 177.397 15.013 176.196 15.013C174.996 15.013 173.886 15.3054 172.87 15.8901C171.854 16.4749 171.029 17.3292 170.398 18.4551C169.768 19.579 169.451 20.9123 169.451 22.4509C169.451 23.9916 169.766 25.3394 170.398 26.4923C171.029 27.6473 171.862 28.5327 172.893 29.1486C173.926 29.7644 175.027 30.0734 176.196 30.0734C177.397 30.0734 178.521 29.7727 179.568 29.1735C180.615 28.5721 181.446 27.7095 182.062 26.5856C182.68 25.4618 182.987 24.1139 182.987 22.5422ZM162.884 22.0217C162.884 19.4339 163.4 17.1405 164.431 15.1374C165.463 13.1364 166.871 11.5957 168.659 10.5175C170.446 9.43921 172.433 8.90007 174.618 8.90007C176.281 8.90007 177.867 9.26294 179.377 9.98662C180.887 10.7103 182.087 11.6725 182.981 12.8751V0.5H189.542V35.4999H182.981V31.1247C182.181 32.3875 181.057 33.4035 179.609 34.1728C178.162 34.9421 176.482 35.3278 174.573 35.3278C172.416 35.3278 170.446 34.7742 168.659 33.6648C166.873 32.5555 165.463 30.992 164.431 28.9765C163.4 26.9568 162.884 24.6406 162.884 22.0217Z" fill="#002A6E"/>
          <path d="M160.232 0.5H153.764V35.4999H160.232V0.5Z" fill="#002A6E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M137.874 29.8266C139.044 29.8266 140.145 29.5404 141.177 28.9661C142.21 28.3917 143.033 27.5311 143.649 26.3845C144.265 25.2378 144.574 23.8422 144.574 22.1979C144.574 19.749 143.935 17.8641 142.656 16.5474C141.378 15.2307 139.815 14.5713 137.967 14.5713C136.12 14.5713 134.571 15.2307 133.325 16.5474C132.076 17.8641 131.454 19.749 131.454 22.1979C131.454 24.6468 132.062 26.5317 133.279 27.8484C134.494 29.1672 136.027 29.8266 137.874 29.8266ZM137.874 35.4999C135.411 35.4999 133.192 34.9504 131.222 33.8493C129.25 32.7483 127.703 31.191 126.579 29.1755C125.455 27.16 124.894 24.8355 124.894 22.2C124.894 19.5645 125.47 17.24 126.625 15.2245C127.78 13.2089 129.358 11.6517 131.361 10.5506C133.362 9.44954 135.595 8.90004 138.061 8.90004C140.524 8.90004 142.757 9.44954 144.761 10.5506C146.762 11.6517 148.342 13.2089 149.497 15.2245C150.652 17.24 151.23 19.5645 151.23 22.2C151.23 24.8355 150.637 27.16 149.451 29.1755C148.265 31.191 146.664 32.7483 144.646 33.8493C142.625 34.9483 140.369 35.4999 137.874 35.4999Z" fill="#002A6E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M113.107 35.4419C111.013 35.4419 109.135 35.0645 107.472 34.3097C105.808 33.5549 104.492 32.5306 103.521 31.2366C102.551 29.9427 102.02 28.5099 101.927 26.9402H108.44C108.562 27.9251 109.047 28.7421 109.896 29.3891C110.742 30.036 111.797 30.3595 113.06 30.3595C114.292 30.3595 115.254 30.1128 115.946 29.6213C116.639 29.1278 116.985 28.4974 116.985 27.7281C116.985 26.8966 116.562 26.2725 115.714 25.8578C114.866 25.4431 113.52 24.9869 111.673 24.4954C109.763 24.033 108.199 23.4379 106.984 22.9465C105.767 22.4529 104.72 21.6982 103.843 20.6821C102.966 19.6661 102.526 18.2954 102.526 16.5702C102.526 15.154 102.935 13.86 103.749 12.6885C104.566 11.519 105.736 10.5942 107.26 9.9161C108.784 9.23804 110.578 8.90004 112.643 8.90004C115.691 8.90004 118.126 9.66312 119.942 11.1872C121.758 12.7113 122.76 14.7683 122.945 17.354H116.755C116.662 16.338 116.239 15.5293 115.484 14.9279C114.729 14.3266 113.721 14.028 112.458 14.028C111.289 14.028 110.387 14.2437 109.757 14.675C109.124 15.1063 108.809 15.7076 108.809 16.4769C108.809 17.3395 109.24 17.9948 110.103 18.4406C110.965 18.8864 112.305 19.3405 114.121 19.8029C115.969 20.2653 117.493 20.7422 118.696 21.2358C119.896 21.7293 120.937 22.6085 121.814 23.6411C122.692 24.6738 123.146 26.0361 123.177 27.7302C123.177 29.2087 122.768 30.5337 121.953 31.7032C121.136 32.8727 119.967 33.7892 118.443 34.4528C116.919 35.1101 115.14 35.4419 113.107 35.4419Z" fill="#002A6E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M64.7559 22.5423C64.7559 20.9705 64.4469 19.6248 63.831 18.5009C63.2152 17.377 62.3837 16.5144 61.3365 15.913C60.2894 15.3117 59.1655 15.0131 57.9649 15.0131C56.7643 15.0131 55.6549 15.3055 54.6389 15.8902C53.6228 16.475 52.7975 17.3293 52.1672 18.4552C51.5368 19.5791 51.2195 20.9124 51.2195 22.451C51.2195 23.9917 51.5347 25.3395 52.1672 26.4924C52.7975 27.6474 53.6311 28.5328 54.6617 29.1487C55.6943 29.7645 56.7954 30.0735 57.9649 30.0735C59.1655 30.0735 60.2894 29.7728 61.3365 29.1736C62.3837 28.5722 63.2152 27.7096 63.831 26.5858C64.449 25.4619 64.7559 24.1141 64.7559 22.5423ZM44.8765 22.1711C44.8765 19.5833 45.4301 17.3086 46.4234 15.2868C47.4083 13.2858 48.7624 11.7451 50.6265 10.6669C52.4222 9.6301 54.3755 9.0495 56.5632 9.0495C58.4729 9.0495 60.1442 9.43519 61.575 10.2045C63.0078 10.9738 64.1545 11.9442 65.0171 13.1158V8.87531H71.5302V35.5001H65.0171V31.3197C64.1856 32.5203 63.0389 33.5136 61.575 34.2995C60.111 35.0853 58.4252 35.4773 56.5155 35.4773C54.3589 35.4773 52.3953 34.9236 50.6244 33.8142C48.8536 32.7049 47.4519 31.1414 46.4213 29.1259C45.3928 27.1062 44.8765 24.79 44.8765 22.1711Z" fill="#002A6E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M31.7817 9.0971C34.9232 9.0971 37.4571 10.0592 39.3814 11.9835C41.3057 13.9078 42.2678 16.5952 42.2678 20.0456V35.4979H35.8003V20.9227C35.8003 18.9217 35.2923 17.3893 34.2763 16.3256C33.2602 15.2639 31.873 14.731 30.1187 14.731C28.3624 14.731 26.969 15.2618 25.9384 16.3256C24.9058 17.3873 24.3915 18.9196 24.3915 20.9227V35.4979H17.924V20.9227C17.924 18.9217 17.416 17.3893 16.3999 16.3256C15.3839 15.2639 13.9967 14.731 12.2424 14.731C10.455 14.731 9.04702 15.2618 8.01438 16.3256C6.98174 17.3873 6.46749 18.9196 6.46749 20.9227V35.4979H0V8.87315H6.46749V12.56C7.299 11.4817 8.36896 10.6357 9.67739 10.0198C10.9858 9.40399 12.427 9.09502 13.9967 9.09502C15.9977 9.09502 17.7851 9.51803 19.3548 10.3661C20.9266 11.2121 22.1417 12.421 23.0043 13.9928C23.8358 12.5144 25.0447 11.3283 26.631 10.4346C28.2152 9.54292 29.9321 9.0971 31.7817 9.0971Z" fill="#002A6E"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M92.5581 9.06804H101.989L94.1631 20.1389L89.4478 13.4682L92.5581 9.06804Z" fill="url(#paint0_linear_1651_3435)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M92.5542 35.3278L101.981 35.3299L94.1488 24.2486L89.4355 30.9152L92.5542 35.3278Z" fill="url(#paint1_linear_1651_3435)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M83.4179 9.06808H73.9956L83.2687 22.1897H92.6931L83.4179 9.06808Z" fill="url(#paint2_linear_1651_3435)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M83.4282 35.3278H73.9976L83.2685 22.1896H92.6929L83.4282 35.3278Z" fill="url(#paint3_linear_1651_3435)"/>
          <defs>
            <linearGradient id="paint0_linear_1651_3435" x1="89.4478" y1="11.0394" x2="103.035" y2="14.4438" gradientUnits="userSpaceOnUse">
              <stop offset="0.0155" stop-color="#011A3C"/>
              <stop offset="0.114273" stop-color="#042045"/>
              <stop offset="0.348374" stop-color="#094285"/>
              <stop offset="0.495021" stop-color="#12599B"/>
              <stop offset="0.677515" stop-color="#3273BF"/>
              <stop offset="0.967897" stop-color="#189AE9"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1651_3435" x1="90.4854" y1="29.4353" x2="103.936" y2="30.8603" gradientUnits="userSpaceOnUse">
              <stop stop-color="#00193B"/>
              <stop offset="0.0907" stop-color="#002651"/>
              <stop offset="0.2953" stop-color="#00417B"/>
              <stop offset="0.4938" stop-color="#00569D"/>
              <stop offset="0.6818" stop-color="#0065B5"/>
              <stop offset="0.8552" stop-color="#006EC4"/>
              <stop offset="1" stop-color="#0071C9"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1651_3435" x1="81.0091" y1="24.4132" x2="86.7312" y2="5.73926" gradientUnits="userSpaceOnUse">
              <stop offset="0.1799" stop-color="#0064AD"/>
              <stop offset="0.2811" stop-color="#067FD0"/>
              <stop offset="0.4688" stop-color="#28B2FF"/>
              <stop offset="0.7204" stop-color="#59D6FF"/>
              <stop offset="0.8617" stop-color="#77E2FF"/>
            </linearGradient>
            <linearGradient id="paint3_linear_1651_3435" x1="76.7077" y1="22.9293" x2="92.0816" y2="34.6546" gradientUnits="userSpaceOnUse">
              <stop offset="0.185764" stop-color="#0780D1"/>
              <stop offset="0.472222" stop-color="#29A8EB"/>
              <stop offset="0.763889" stop-color="#42C5FF"/>
            </linearGradient>
          </defs>
        </svg>

        <!-- <svg
          role="img"
          aria-label="Maxsold home page"
          class="mxs-h-8 mxs-w-40 sm:mxs-h-10 sm:mxs-w-56 mxs-cursor-pointer"
          preserveAspectRatio="xMidYMid meet"
          data-bbox="8.57 19.67 841.43 158.57"
          viewBox="8.57 19.67 841.43 158.57"
          height="2.64in"
          width="12.04in"
          xmlns="http://www.w3.org/2000/svg"
          data-type="color"
        >
          <g>
            <path
              fill="#30333a"
              d="M474.63 138.84c0 6.82 4.78 15.92 21.61 15.92 11.82 0 17.28-5.46 17.28-10.92 0-7.5-8.87-9.32-20.47-11.14-20.69-3.64-48.44-5.91-48.44-37.53 0-20.92 18.65-37.75 48.22-37.75 29.79 0 48.21 15 50 38.66h-31.58c-.68-8.41-3.87-14.78-18-14.78-9.1 0-16.6 3-16.6 10.24 0 8.64 12.05 10.46 26.15 13 20 3.64 44.35 7.73 44.35 33.89 0 27.74-23.2 39.34-52.54 39.34-31.61 0-51.39-16.6-52.08-38.89z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M676.61 117.46c0 33.66-27.06 60.73-62.76 60.73-35.94 0-62.55-26.61-62.55-60.73 0-33.88 26.84-60.95 62.55-60.95s62.76 27.07 62.76 60.95zm-94.15 0c0 18.65 13 32.52 30.7 32.52s32.07-13.87 32.07-32.52c0-18.87-13.65-32.29-31.38-32.29s-31.39 13.42-31.39 32.29z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M685.05 175.46V19.67h31.84v155.79z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M818.34 166.81c-8.19 6.37-20.7 10.24-34.12 10.24a59 59 0 0 1-58.9-59.36c0-33.66 27.06-60 58.9-60 13.42 0 25.93 4.09 34.12 10.46V19.67H850v155.79h-31.66zm.45-49.12c0-17.28-14.32-31.39-31.84-31.39-17.28 0-30.7 14.11-30.7 31.39 0 17.51 13.42 31.38 30.7 31.38 18.2 0 31.84-13.87 31.84-31.38z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M39.73 71.52A45.17 45.17 0 0 1 72 57.88c13.42 0 25 4.77 33.44 15.92 9.09-9.78 21.15-15.92 38.2-15.92 28.43 0 45.49 18.42 45.49 46.39v72.32h-31.37V109.5c0-13.64-7-22.51-19.55-22.51-14.33 0-23.66 9.55-23.66 25.24v64.36H83.39V109.5c0-13.64-7-22.51-19.55-22.51-14.33 0-23.66 9.55-23.66 25.24v64.36H8.57V59.92h31.16z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M292.88 168c-8.19 6.37-20.7 10.24-34.11 10.24a59 59 0 0 1-58.91-59.36c0-33.66 27.06-60 58.91-60 13.41 0 25.92 4.1 34.11 10.47v-9.43h31.61v116.67h-31.61zm-62.09-49.12c0 17.51 13.42 31.38 30.7 31.38 18.2 0 31.85-13.87 31.85-31.38 0-17.29-14.33-31.39-31.85-31.39-17.28-.05-30.7 14.05-30.7 31.34z"
              data-color="1"
            />
            <path
              fill="#e13b35"
              d="M367.73 59.7h-35.94l39.46 58.33 17.97-26.56-21.49-31.77z"
              data-color="2"
            />
            <path
              fill="#30333a"
              d="m389.22 144.59 21.49 31.78h35.93l-39.45-58.34-17.97 26.56z"
              data-color="1"
            />
            <path
              fill="#e13b35"
              d="M331.79 176.37h35.94l21.49-31.78-17.97-26.56-39.46 58.34z"
              data-color="2"
            />
            <path
              fill="#30333a"
              d="M446.64 59.7h-35.93l-21.49 31.77 17.97 26.56 39.45-58.33z"
              data-color="1"
            />
            <path
              fill="#c72127"
              d="m389.22 91.47-17.97 26.56 17.97 26.56 17.97-26.56-17.97-26.56z"
              data-color="3"
            />
          </g>
        </svg> -->
      </a>
      {#if partnerLogoURI && partnerLogoURI !== ""}
        <div
          class="mxs-h-[76px] lg:mxs-h-24 mxs-hidden lg:mxs-flex lg:mxs-flex-col mxs-py-2"
        >
          <p class="mxs-my-1">
            In <span class="mxs-font-bold">partnership</span> with
          </p>
          <div
            class="w-full mxs-flex-grow mxs-flex mxs-items-center mxs-justify-center"
          >
            <div
              class="mxs-w-full mxs-h-14 mxs-flex mxs-items-center mxs-justify-center"
            >
              <img
                class="mxs-max-w-[100%] mxs-max-h-[100%]"
                src={partnerLogoURI}
                alt={partnerTitle}
              />
            </div>
          </div>
        </div>
      {/if}
      <p
        class="mxs--mb-1 md:mxs--mb-1.5 mxs-text-2xl mxs-text-maxsold-accent-default"
      >
        {sectionTitle}
      </p>
    </div>
  </div>

  <nav
    aria-label="secondary links"
    class="mxs-flex mxs-items-center mxs-justify-center mxs-h-10 mxs-gap-5 mxs-text-lg"
  >
    {#each urls as { label, url }, i}
      <a
        class="maxsold-link mxs-text-black hover:mxs-text-maxsold-accent-default mxs-no-underline mxs-font-bold mxs-hidden md:mxs-block mxs-text-center"
        href={url}
      >
        {label}
      </a>
    {/each}

    {#if isAuthenticated}
      <UserMenu {userName} {isAuthenticated} />
    {:else}
      <LoginMenu />
    {/if}
  </nav>
</div>

<nav
  aria-label="secondary links"
  class="mxs-flex mxs-items-start mxs-justify-center mxs-px-4 mxs-bg-white md:mxs-hidden mxs-p-3 mxs-gap-5"
>
  {#each urls as { label, url }, i}
    <a
      class="mxs-text-center maxsold-link mxs-text-black hover:mxs-text-maxsold-accent-default mxs-no-underline mxs-font-bold"
      href={url}
    >
      {label}
    </a>
  {/each}
</nav>
